import React from 'react'
import { Row, Col, FormGroup, Input, Label, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'

class AddressFormField extends React.Component {
  constructor(props) {
    super(props)
    const defaultValue = props.defaultValue;
    this.state = {
      error: this.getValidationError(defaultValue)
    };
    this.onChange = (e) => {
      e.preventDefault()
      const val = e.target.value.trim()
      const validationError = this.getValidationError(val)
      this.setState({ error: validationError })
      props.onChange(val)
    }
  }
  getValidationError(value) {
    return this.props.validate && this.props.validate(value) || null
  }
  render() {
    return (
      <FormGroup row>
        <Col sm={3}>
          <Label className="form-label">{this.props.name}</Label>
        </Col>
        <Col sm={9}>
          <Input invalid={this.state.error !== null && this.state.error.length > 0} defaultValue={this.props.defaultValue} onChange={this.onChange} />
          {this.state.error && (
            <small className='text-danger'>{this.state.error}</small>
          )}
        </Col>
      </FormGroup>
    )
  }
}

const validateAddress = (val) => {
  if (val && val.length > 30) {
    return 'Address cannot exceed 30 characters.'
  }
  return null
}

const validateAddress1 = (val) => {
  if (val && val.length > 30) {
    return 'Address cannot exceed 30 characters.'
  } else if (!val || !val.trim().length) {
    return 'Address 1 cannot be blank'
  }
  return null
}

const validateRequired = (val) => {
  if (!val || !val.trim().length) {
    return 'Cannot be blank.'
  }
  return null
}

const validatePhone = (val) => {
  if (val && val.length > 30) {
    return 'Phone number cannot exceed 30 characters.'
  } else if (!val || !val.trim().length) {
    return 'Phone number cannot be blank'
  }
  return null
}

const addressFields = [
  {
    name: 'Email',
    field: 'email'
  },
  {
    name: 'Name',
    field: 'name',
    validate: validateRequired
  },
  {
    name: 'Company',
    field: 'company'
  },
  {
    name: 'Phone number',
    field: 'phone',
    validate: validatePhone
  },
  {
    name: 'Address',
    field: 'address1',
    validate: validateAddress1
  },
  {
    name: '',
    field: 'address2',
    validate: validateAddress
  },
  {
    name: '',
    field: 'address3',
    validate: validateAddress
  },
  {
    name: 'City',
    field: 'city'
  },
  {
    name: 'Postal code / ZIP ',
    field: 'zip',
    validate: validateRequired
  }
]

export default class AddressForm extends React.Component {
  constructor(props) {
    super(props)
    this.fieldChanged = this.fieldChanged.bind(this);

    const address = {};
    for (let addressField of addressFields) {
      address[addressField.field] = props.address[addressField.field] || ''
    }
    let availableStates = [];
    const defaultCountryName = props.address.country;
    if (props.address.country) {
      const country = props.countries.find(country => country.name === defaultCountryName);
      if (country) {
        address.country = defaultCountryName;
        availableStates = country.states || [];
        const defaultStateName = props.address.state;
        const state = country.states.find(state => state.name === defaultStateName);
        if (state) {
          address.state = defaultStateName;
        }
      }
    }

    this.state = {
      address,
      availableStates,
      countryDropdownOpen: false,
      stateDropdownOpen: false,
      valid: this.validAddress(address)
    };
  }

  countryChanged(countryName) {
    if (this.state.address.country === countryName) {
      return;
    }
    const country = this.props.countries.find(country => country.name === countryName);
    const address = Object.assign({}, this.state.address, { country: countryName, state: '' });
    const state = { address, availableStates: country.states || [] };
    this.addressChanged(address);

    if (country.states.length > 1) {
      state.stateError = 'Select a state or province';
    }
    this.setState(state);
  }

  validAddress(address) {
    for (let addressField of addressFields) {
      const value = address[addressField.field];
      const isInvalid = addressField.validate && addressField.validate(value);
      if (isInvalid)
        return false;
    }
    if (!address.country) {
      return false;
    }
    const country = this.props.countries.find(country =>
      country.name === address.country);
    if (
      !country
      || (country.states.length
        && (!address.state || !country.states.find(state => state.name === address.state)))
    ) {
      return false;
    }
    return true;
  }

  fieldChanged(key, value) {
    const address = Object.assign({}, this.state.address);
    address[key] = value;
    this.setState({ address });
    this.addressChanged(address);
  }

  addressChanged(address) {
    this.props.onAddressChange(address, this.validAddress(address));
  }
  render() {
    return (
      <div>
        {addressFields.map((addressField, index) => (
          <AddressFormField
            key={index}
            name={addressField.name}
            defaultValue={this.props.address[addressField.field]}
            validate={addressField.validate}
            onChange={(val) => {
              this.fieldChanged(addressField.field, val)
            }} />
        ))}
        {this.state.availableStates.length > 1 && (
          <FormGroup row>
            <Col sm={3}>
              <Label className="form-label">State / Province</Label>
            </Col>
            <Col sm={9}>
              <Dropdown isOpen={this.state.stateDropdownOpen} toggle={() => this.setState({ stateDropdownOpen: !this.state.stateDropdownOpen })}>
                <DropdownToggle caret>
                  {this.state.address.state || 'Select a state or province'}
                </DropdownToggle>
                <DropdownMenu>
                  {this.state.availableStates.map(state => (
                    <DropdownItem key={state.name} onClick={() => this.fieldChanged('state', state.name)}>{state.name.toUpperCase()}</DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {this.state.availableStates.length > 0 && !this.state.address.state && (
                <small className='text-danger'>Select a state or province</small>
              )}
            </Col>
          </FormGroup>
        )}
        <FormGroup row>
          <Col sm={3}>
            <Label className="form-label">Country</Label>
          </Col>
          <Col sm={9}>
            <Dropdown isOpen={this.state.countryDropdownOpen} toggle={() => this.setState({ countryDropdownOpen: !this.state.countryDropdownOpen })}>
              <DropdownToggle caret>
                {this.state.address.country || 'Select a country'}
              </DropdownToggle>
              <DropdownMenu>
                {this.props.countries.map(country => (
                  <DropdownItem key={country.name} onClick={() => this.countryChanged(country.name)}>{country.name}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            {this.state.countryError && (
              <small className='text-danger'>{this.state.countryError}</small>
            )}
          </Col>
        </FormGroup>
      </div >
    )
  }
  componentDidMount() {
    this.addressChanged(this.state.address)
  }
}
